const GameState = Object.freeze({
  fresh: 0,
  inGame: 1,
  justWon: 2,
  gaveUp: 3,
});

const GameModes = Object.freeze({
  parabola: 'Parabola',
  hard: 'Hard',
  jankyHard: 'Janky Hard',
  jankyReallyHard: 'Janky Really Hard',
  jankyExtreme: 'Janky Extreme',
  linear: 'Linear',
  sinusoidal: 'Sinusoidal',
});

const FunctionType = Object.freeze({
  binary: 0,
  unary: 1,
});

const BinaryOps = Object.freeze({
  add: '+',
  times: '\\cdot',
  exponent: '^',
  gcd: '\\gcd',
  lcm: '\\operatorname{lcm}',
  mod: '\\operatorname{mod}',
});

const UnaryOps = Object.freeze({
  // plusC: 'plusC',
  // timesC: 'timesC',
  sin: '\\sin',
  cos: '\\cos',
  tan: '\\tan',
  ln: '\\ln',
  sinh: '\\sinh',
  cosh: '\\cosh',
  tanh: '\\tanh',
  ceil: '\\operatorname{ceil}',
  round: '\\operatorname{round}',
  floor: '\\operatorname{floor}',
  sign: '\\operatorname{sign}',
});

export {
  GameState,
  GameModes,
  FunctionType,
  BinaryOps,
  UnaryOps,
};

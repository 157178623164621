import React, { useEffect } from 'react';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { addStyles } from 'react-mathquill';
import endIfFunctionsMatch from '../scripts/checker';
import { GameState } from '../scripts/enums';

const styles = {
  calculator: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  giveUpButton: {
    position: 'absolute',
    right: '2%',
    bottom: '2%',
  },
};

export default function DesmosGraph({ setCalcObj, setGameState }) {
  // this is set up to run once, when mounted
  useEffect(() => {
    addStyles();
    const elt = document.getElementById('calculator');
    const calcObj = window.Desmos.GraphingCalculator(elt);
    setCalcObj(calcObj);

    calcObj.observe('expressionAnalysis', () => endIfFunctionsMatch(calcObj, setGameState));
  }, []);

  return (
    <>
      <div id="calculator" style={styles.calculator} />
      <Button
        className="giveUpButton"
        onClick={() => {
          setGameState(GameState.gaveUp);
        }}
        style={styles.giveUpButton}
        variant="contained"
        color="secondary"
      >
        Give Up
      </Button>
    </>
  );
}

DesmosGraph.propTypes = {
  setCalcObj: PropTypes.func.isRequired,
  setGameState: PropTypes.func.isRequired,
};

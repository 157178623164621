const startOverlayTexts = Object.freeze({
  header: 'Welcome to Graph Game!',
  paragraph: 'Try to match the function displayed on the graph. Select the game mode to begin!',
});

const winningOverlayTexts = Object.freeze({
  header: 'You won!',
  paragraph: 'Select your next game mode to play again!',
});

const givingUpOverlayTexts = Object.freeze({
  header: 'So close! The equation was:',
  paragraph: 'Select your next game mode to play again!',
});

const expressionInstructions = 'Write and modify your function, g(x), until it looks like the curve, f(x), shown on the graph! Note that all constants will only ever be -1, 0, or 1, with exception for the sum of the same function. For examples sin(x) + sin(x) = 2sin(x)';

export {
  startOverlayTexts,
  winningOverlayTexts,
  givingUpOverlayTexts,
  expressionInstructions,
};

const constants = {
  TARGET_FUNCTION_ID: 'targetFunction',
  TARGET_FUNCTION_NAME: 'f\\left(x\\right)',
  ANSWER_FUNCTION_PREFIX: 'g\\left(x\\right) =',
  // 0 + (pos/neg) between 0 and 1 + (pos/neg) above 1
  // Must be multiple of 4 plus 1.
  NUM_CHECK_POINTS: 33,
  CHECK_POINT_ID_PREFIX: 'checkPoint',
};

export default constants;

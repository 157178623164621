import constants from './constants';
import { GameState } from './enums';

function checkMatch(calcObj) {
  for (let i = 0; i < constants.NUM_CHECK_POINTS; i += 1) {
    // getting checking expr for f(x)
    const checkPointIDStrF = `${constants.CHECK_POINT_ID_PREFIX}${i}F`;
    const exprAnalysisF = calcObj.expressionAnalysis[checkPointIDStrF];

    // getting checking expr for g(x)
    const checkPointIDStrG = `${constants.CHECK_POINT_ID_PREFIX}${i}G`;
    const exprAnalysisG = calcObj.expressionAnalysis[checkPointIDStrG];

    // This just means id hasn't updated yet in expressionAnalysis dict
    if (exprAnalysisF == null || exprAnalysisG == null) {
      return false;
    }
    const checkPointValF = exprAnalysisF.evaluation?.value;
    const checkPointValG = exprAnalysisG.evaluation?.value;
    // special care for NaN results.
    const isSameDefinedStatus = Number.isNaN(checkPointValF) === Number.isNaN(checkPointValG);
    const isAtLeastOneNaN = Number.isNaN(checkPointValF) || Number.isNaN(checkPointValG);
    const isPointMatch = isSameDefinedStatus
      && (isAtLeastOneNaN || checkPointValF === checkPointValG);

    if (!isPointMatch) {
      return false;
    }
  }
  return true;
}

function endIfFunctionsMatch(calcObj, setGameState) {
  const isMatch = checkMatch(calcObj);
  if (isMatch) {
    setGameState(GameState.justWon);
  }
}

export default endIfFunctionsMatch;

import React from 'react';
import PropTypes from 'prop-types';
import { StaticMathField } from 'react-mathquill';
import { Paper, Button } from '@material-ui/core';
import generateLevel from '../scripts/generateExpressions';
import { GameModes, GameState } from '../scripts/enums';

const styles = {
  overlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1,
  },
  paper: {
    position: 'absolute',
    padding: '25px',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    margin: '0 auto',
    textAlign: 'center',
    backgroundColor: '#fafaff',
    zIndex: 2,
  },
  start_button: {
    display: 'block',
    margin: '10px auto',
  },
};

export default function StartOverlay({
  calcObj,
  setGameState,
  setTargetFuncStr,
  headerText,
  equationText,
  paragraphText,
}) {
  return (
    <div style={styles.overlay}>
      <Paper style={styles.paper}>
        <h1>{headerText}</h1>
        {equationText && (<StaticMathField>{equationText}</StaticMathField>)}
        <p>{paragraphText}</p>
        <Button
          color="primary"
          onClick={() => {
            setGameState(GameState.inGame);
            const targetFuncStr = generateLevel(calcObj, GameModes.parabola);
            setTargetFuncStr(targetFuncStr);
          }}
          style={styles.start_button}
          variant="contained"
        >
          {GameModes.parabola}
        </Button>
        <Button
          color="primary"
          onClick={() => {
            setGameState(GameState.inGame);
            const targetFuncStr = generateLevel(calcObj, GameModes.jankyHard);
            setTargetFuncStr(targetFuncStr);
          }}
          style={styles.start_button}
          variant="contained"
        >
          {GameModes.jankyHard}
        </Button>
        <Button
          color="primary"
          onClick={() => {
            setGameState(GameState.inGame);
            const targetFuncStr = generateLevel(calcObj, GameModes.jankyReallyHard);
            setTargetFuncStr(targetFuncStr);
          }}
          style={styles.start_button}
          variant="contained"
        >
          {GameModes.jankyReallyHard}
        </Button>
        <Button
          color="primary"
          onClick={() => {
            setGameState(GameState.inGame);
            const targetFuncStr = generateLevel(calcObj, GameModes.jankyExtreme);
            setTargetFuncStr(targetFuncStr);
          }}
          style={styles.start_button}
          variant="contained"
        >
          {GameModes.jankyExtreme}
        </Button>
      </Paper>
    </div>
  );
}

StartOverlay.propTypes = {
  headerText: PropTypes.string,
  equationText: PropTypes.string,
  paragraphText: PropTypes.string,
  setGameState: PropTypes.func.isRequired,
  setTargetFuncStr: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  calcObj: PropTypes.object,
};

StartOverlay.defaultProps = {
  headerText: null,
  equationText: null,
  paragraphText: null,
  calcObj: null,
};

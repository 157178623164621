/* eslint-disable no-unused-vars */
import constants from './constants';
import { expressionInstructions } from './texts';

import {
  GameModes,
  FunctionType,
  BinaryOps,
  UnaryOps,
} from './enums';

// generates an integer in set [min, max)
function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min) + min);
}

function getRandomKey(obj) {
  const keys = Object.keys(obj);
  return keys[Math.floor(keys.length * Math.random())];
}

function resetGraph(calcObj) {
  calcObj.removeExpressions(calcObj.getExpressions());
}

function writeUserHelpExpressions(calcObj) {
  // directly modifying calc state to add helper text and starting eq.
  const calcState = calcObj.getState();
  calcState.expressions.list.push({
    text: expressionInstructions,
    type: 'text',
  });
  calcState.expressions.list.push({
    type: 'expression',
    latex: 'g\\left(x\\right) = ',
  });
  calcObj.setState(calcState);
}

function generateEvaluationExpressions(calcObj) {
  const maxExponent = Math.floor((constants.NUM_CHECK_POINTS - 1) / 4);

  const evalPointStrs = ['0'];
  // adding values
  for (let i = -1 * maxExponent; i <= maxExponent; i += 1) {
    const evalPointStrPos = `${getRandomInt(1, 10)} \\cdot 10^{${i}}`;
    const evalPointStrNeg = `-${getRandomInt(1, 10)} \\cdot 10^{${i}}`;
    evalPointStrs.push(evalPointStrPos);
    evalPointStrs.push(evalPointStrNeg);
  }

  for (let i = 0; i < evalPointStrs.length; i += 1) {
    const evalPointStr = evalPointStrs[i];
    calcObj.setExpression({
      id: `${constants.CHECK_POINT_ID_PREFIX}${i}F`,
      latex: `f\\left(${evalPointStr}\\right)`,
      secret: true,
    });

    calcObj.setExpression({
      id: `${constants.CHECK_POINT_ID_PREFIX}${i}G`,
      latex: `g\\left(${evalPointStr}\\right)`,
      secret: true,
    });
  }
}

function generateParabola(calcObj) {
  const coeffA = getRandomInt(-1, 2);
  const coeffB = getRandomInt(-1, 2);
  const coeffC = getRandomInt(-1, 2);
  calcObj.setExpression({
    id: 'targetFunction',
    latex: `f(x) = ${coeffA}x^2 + ${coeffB}x + ${coeffC}`,
    secret: true,
    color: '#000000',
    lineStyle: window.Desmos.Styles.DASHED,
  });
}

function generateExpressionConfigurableRecursive(numOps, options) {
  // base case identity function
  if (numOps.binary === 0 && numOps.unary === 0) {
    return 'x';
  }

  // This block determines whether to choose binary or unary expr.
  let choiceOfFunctionType;
  if (numOps.binary === 0) {
    choiceOfFunctionType = FunctionType.unary;
  } else if (numOps.unary === 0) {
    choiceOfFunctionType = FunctionType.binary;
  } else {
    const totalOps = numOps.binary + numOps.unary;
    if (Math.random() <= numOps.binary / totalOps) {
      choiceOfFunctionType = FunctionType.binary;
    } else {
      choiceOfFunctionType = FunctionType.unary;
    }
  }

  // updating count in numOps obj
  if (choiceOfFunctionType === FunctionType.binary) {
    // eslint-disable-next-line no-param-reassign
    numOps.binary -= 1;
  } else {
    // eslint-disable-next-line no-param-reassign
    numOps.unary -= 1;
  }

  let expression = '';
  if (choiceOfFunctionType === FunctionType.binary) {
    // choosing a specific binary function and adding it to string
    const binFunc = getRandomKey(BinaryOps);
    // these require special care as they are atypical and not in g(x,y) notation
    if (binFunc === 'add' || binFunc === 'times' || binFunc === 'exponent') {
      expression += '\\left(';
      expression += '{';
      expression += generateExpressionConfigurableRecursive(numOps, options);
      expression += '}';
      expression += BinaryOps[binFunc];
      expression += '{';
      expression += generateExpressionConfigurableRecursive(numOps, options);
      expression += '}';
      expression += '\\right)';

      // the format of these functions are more typical and in g(_,_) format
    } else {
      expression += BinaryOps[binFunc];
      expression += '\\left(';
      expression += generateExpressionConfigurableRecursive(numOps, options);
      expression += ',';
      expression += generateExpressionConfigurableRecursive(numOps, options);
      expression += '\\right)';
    }
    // all unary functions are of typical form g(_)
  } else if (choiceOfFunctionType === FunctionType.unary) {
    const unaFunc = getRandomKey(UnaryOps);
    expression += UnaryOps[unaFunc];
    expression += '\\left(';
    expression += generateExpressionConfigurableRecursive(numOps, options);
    expression += '\\right)';
  }

  return expression;
}

function generateExpressionConfigurable(calcObj, numBinary, numUnary, options) {
  const numOps = {
    binary: numBinary,
    unary: numUnary,
  };
  const expressionStr = generateExpressionConfigurableRecursive(numOps, options);
  const equationStr = `f(x) = ${expressionStr}`;

  calcObj.setExpression({
    id: 'targetFunction',
    latex: equationStr,
    secret: true,
    color: '#000000',
  });

  return equationStr;
}

function generateLevel(calcObj, chosenGameMode) {
  resetGraph(calcObj);
  generateEvaluationExpressions(calcObj);
  let targetFuncStr;
  if (chosenGameMode === GameModes.parabola) {
    generateParabola(calcObj);
  } else if (chosenGameMode === GameModes.jankyHard) {
    targetFuncStr = generateExpressionConfigurable(calcObj, 1, 1, null);
  } else if (chosenGameMode === GameModes.jankyReallyHard) {
    targetFuncStr = generateExpressionConfigurable(calcObj, 2, 2, null);
  } else if (chosenGameMode === GameModes.jankyExtreme) {
    targetFuncStr = generateExpressionConfigurable(calcObj, 3, 3, null);
  } else {
    targetFuncStr = generateExpressionConfigurable(calcObj, 1, 1, null);
  }
  writeUserHelpExpressions(calcObj);

  return targetFuncStr;
}

export default generateLevel;

import React, { useState } from 'react';
import DesmosGraph from './DesmosGraph.react';
import StartOverlay from './StartOverlay.react';
import { GameState } from '../scripts/enums';
import { startOverlayTexts, winningOverlayTexts, givingUpOverlayTexts } from '../scripts/texts';

export default function Home() {
  const [gameState, setGameState] = useState(GameState.fresh);
  const [targetFuncStr, setTargetFuncStr] = useState(undefined);
  const [calcObj, setCalcObj] = useState(null);

  return (
    <>
      <DesmosGraph setCalcObj={setCalcObj} setGameState={setGameState} />
      {gameState === GameState.fresh && (
        <StartOverlay
          calcObj={calcObj}
          setGameState={setGameState}
          setTargetFuncStr={setTargetFuncStr}
          headerText={startOverlayTexts.header}
          paragraphText={startOverlayTexts.paragraph}
        />
      )}
      {gameState === GameState.justWon && (
        <StartOverlay
          calcObj={calcObj}
          setGameState={setGameState}
          setTargetFuncStr={setTargetFuncStr}
          headerText={winningOverlayTexts.header}
          paragraphText={winningOverlayTexts.paragraph}
        />
      )}
      {gameState === GameState.gaveUp && (
        <StartOverlay
          calcObj={calcObj}
          setGameState={setGameState}
          setTargetFuncStr={setTargetFuncStr}
          headerText={givingUpOverlayTexts.header}
          equationText={targetFuncStr}
          paragraphText={givingUpOverlayTexts.paragraph}
        />
      )}
    </>
  );
}
